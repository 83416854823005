<template>
    <div>
        <!--面包屑-->
        <div class="page-breadcrumb">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item>商户类别</el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <!--内容-->
        <div class="page-content-x">
            <!--标题-->
            <el-row>
                <el-col :span="12">
                    <h3>{{ page_name }}</h3>
                </el-col>
                <el-col :span="12">
                    <div class="top-operation-button">
                        <el-button v-if="is_auth('company.merchantcategory.issave')" icon="el-icon-plus" size="medium"
                            type="primary" @click="add_page()">添加
                        </el-button>
                    </div>
                </el-col>
            </el-row>
            <div style="height: 20px;"></div>
            <!--列表-->
            <el-table size="medium" border v-loading="loading" :data="tableData" style="width: 100%">
                <el-table-column prop="id" label="ID" width="60" />
                <el-table-column prop="name" label="类别名称" />
                <el-table-column prop="create_at" label="创建时间">
                </el-table-column>
                <el-table-column prop="state" label="状态">
                    <template slot-scope="scope">
                        <el-tag size="mini" v-if="scope.row.state === 0" type="default">待启用</el-tag>
                        <el-tag size="mini" v-if="scope.row.state === 1" type="success">正常</el-tag>
                        <el-tag size="mini" v-if="scope.row.state === 2" type="danger">禁用</el-tag>
                    </template>
                </el-table-column>
                <el-table-column fixed="right" label="操作" width="240">
                    <template slot-scope="scope">
                        <el-button v-if="is_auth('company.merchantcategory.issave')" type="primary"
                            @click="editCategory(scope.row)" size="mini">编辑
                        </el-button>
                        <el-button
                            v-if="is_auth('company.merchantcategory.isenable') && (scope.row.state === 2 || scope.row.state === 0)"
                            @click="operation_tip(scope.row.id, scope.row.name, 'isenable')" type="primary" size="mini">
                            启用
                        </el-button>
                        <el-button v-if="is_auth('company.merchantcategory.isdisable') && scope.row.state === 1"
                            @click="operation_tip(scope.row.id, scope.row.name, 'isdisable')" type="danger" size="mini">
                            禁用
                        </el-button>
                        <el-button v-if="is_auth('company.merchantcategory.isdel')"
                            @click="operation_tip(scope.row.id, scope.row.name, 'isdel')" type="danger" size="mini">删除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div style="height: 20px;"></div>
            <!--分页-->
            <el-pagination @current-change="getlist" :page-size="this.env.pageSize"  background
                layout="prev, pager, next, total" :current-page.sync="page" :total="count">
            </el-pagination>

        </div>
        <!--编辑-->
        <el-dialog v-loading="loading" v-if="is_auth('company.merchantcategory.issave')" title="商户类别"
            :visible.sync="editPage" width="40%" :close-on-press-escape="false" :close-on-click-modal="false"
            @closed="closeDialog">
            <el-row>
                <el-form size="medium" :label-width="this.env.label_width" :model="info" @submit.native.prevent
                    @keyup.enter.native="save()">
                    <el-col :span="16">
                        <el-form-item label="名称" prop="ad_name">
                            <el-input v-model="info.name" maxlength="20" placeholder="请输入商户类别名称（最多20个字符）" />
                        </el-form-item>
                    </el-col>
                </el-form>
            </el-row>
            <el-row style="margin: 10px 0px">
                <el-button size="medium" type="primary" @click="save">{{ info.id  ? '编 辑' : '添 加'}}</el-button>
                <el-button size="medium" @click="closeDialog">取 消</el-button>
            </el-row>
        </el-dialog>
    </div>
</template>
<script>
export default {
    data() {
        return {
            page_name: '商户类别',
            loading: true,      // 加载状态
            issearch: false,    // 搜索是否展示
            search: {},         // 搜索内容
            count: 0,           // 数据总条数
            page: 1,            // 当前页数
            tableData: [],// 列表内容
            searchtime: {},
            editPage: false,
            info: {}, // 编辑数据
        }
    },
    // 创建
    created() {
        this.init()

    },
    // 安装
    mounted() {
    },
    methods: {
        // 权限判断
        is_auth(auth) {
            return this.Tool.is_auth(auth)
        },
        // 初始化
        init() {
            this.search = {
                tag_s: '',
            }
            this.search = {
                name: "",
                state: "",
            }
            this.searchtime = null
            this.is_search();
        },
        //搜索
        is_search() {
            this.page = 1
            this.getlist();
        },
        // 获取列表
        getlist() {
            let postdata = {
                api_name: "company.merchantcategory.getlist",
                token: this.Tool.get_l_cache('token'),
                pagesize: this.env.pageSize,
                page: this.page,
            }
            // 非必须参数（搜索条件）
            Object.assign(postdata, this.search);
            if (this.searchtime !== null) {
                postdata.starttime = this.searchtime[0];
                postdata.endtime = this.searchtime[1];
            }
            this.loading = true
            this.Tool.post_data('oss', postdata, (json) => {
                this.loading = false
                if (json.code === 0) {
                    this.tableData = json.data.list
                    this.count = json.data.count
                } else {
                    this.tableData = []
                    this.count = 0
                    this.Tool.errormes(json)
                }
            })
        },
        // 操作提示
        operation_tip(id = '', title = '', operation = '') {
            let tip = ''
            let options = {}
            // 启用
            if (operation === 'isenable') {
                tip = '启用【' + title + '】？'
            }
            // 禁用
            if (operation === 'isdisable') {
                tip = '禁用【' + title + '】？'
            }
            // 删除
            if (operation === 'isdel') {
                tip = '删除【' + title + '】？'
                options = { type: 'error' }
            }
            // 弹出二次确认
            this.$confirm(tip, '确认信息', options)
                .then(() => {
                    this.isoperation(id, operation)
                })
                .catch(() => {
                });
        },
        // 操作
        isoperation(id = '', operation = '') {
            let postdata = {
                api_name: `company.merchantcategory.${operation}`,
                token: this.Tool.get_l_cache('token'),
                id: id,
            }
            this.loading = true
            this.Tool.post_data('oss', postdata, (json) => {
                this.loading = false
                if (json.code === 0) {
                    this.$message({
                        message: '操作成功', type: 'success',
                        duration: this.env.message_duration,
                        onClose: () => {
                            this.getlist()    // 刷新列表(重新搜索)
                        }
                    });
                } else {
                    this.Tool.errormes(json)
                }
            })
        },
        // 添加新类别
        add_page() {
            this.editPage = true;
        },
        // 保存/编辑
        save() {
            let postdata = {
                api_name: "company.merchantcategory.issave",
                token: this.Tool.get_l_cache("token"),
            };
            Object.assign(postdata, this.info);
            if (!postdata.name) {
                this.$message.error('请输入商户类别名称');
            } else {
                this.Tool.post_data("oss", postdata, (json) => {
                    if (json.code === 0) {
                        this.editPage = false;
                        this.$message({
                            message: "保存成功",
                            type: "success",
                            duration: this.env.message_duration,
                            onClose: () => {
                                this.getlist();
                            },
                        });
                    } else {
                        this.$message.error(json.message);
                    }
                })
            }
        },
        // 关闭添加/编辑弹窗
        closeDialog() {
            this.editPage = false;
            this.info = {};
        },
        // 编辑品类
        editCategory(item) {
            const info = {};
            info['id'] = item.id;
            info['name'] = item.name;
            this.info = info;
            this.editPage = true;

        }


    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.form-tag_colour-div {
    min-height: 200px;
}
</style>
